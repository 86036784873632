




























































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { mapGetters } from "vuex";
// import BtnCreate from "../buttons/BtnCreate.vue";
// import AllowIf from "@/components/AllowIf";
// import BtnRequestPatients from "../buttons/BtnRequestPatients.vue";

export default Vue.extend({
  name: "crm-secondary-toolbar",
  // components: { BtnCreate },
  data() {
    return {
      isAdmin: false,
      isDoctor: false,
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
  },
  mounted() {
    if (
      this.$store.state.profile.rol == "ADMIN" ||
      this.$store.state.profile.rol == "SUPER"
    ) {
      this.isAdmin = true;
    }
    if (this.$store.state.profile.rol == "DOCTOR") {
      this.isDoctor = true;
    }
  },
});
